/*
*   ==================================================
*   Variables Stylesheet
*   ==================================================
*/



/*
*   =========================
*   Colours
*   =========================
*/
$color-primary: #00E3A5;

$color-black: #000;
$color-body-background: #0D0D0D;
$color-white: #FFF;
$color-grey-1: #E8E8E8;

$color-body-text: #80AB9E;



/*
*   =========================
*   Breakpoints
*   =========================
*/
$spacing: 16px;

$spacing-sm: calc($spacing / 2);

$spacing-lg: calc($spacing * 1.5);
$spacing-x2: calc($spacing * 2);



/*
*   =========================
*   Breakpoints
*   =========================
*/
$bp-s: 480px;
$bp-m: 600px;
$bp-l: 992px;