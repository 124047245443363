/*
*   ==================================================
*   Main Stylesheet
*   ==================================================
*/



/*
*   =========================
*   Base
*   @import "Base/";
*   =========================
*/
@import "Base/normalize";
@import "Base/variables";
@import "Base/mixins";

@import "Base/helpers";
@import "Base/layout";
@import "Base/typography";



/*
*   =========================
*   Components
*   @import "Components/";
*   =========================
*/
@import "Components/Inputs/buttons";

@import "Components/Layout/header";

@import "Components/Section/about-section";
@import "Components/Section/achievements-section";
@import "Components/Section/intro-section";









body {
    min-height: 250vh;
}