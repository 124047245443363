/*
*   ==================================================
*   Input / Button Stylesheet
*   .btn
*   ==================================================
*/



/*
*   =========================
*   Default / Primary
*   =========================
*/
.btn {
    position: relative;

    padding: 17px 22px;
    
    color: #181818;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.05rem;
    text-align: center;

    background-color: $color-primary;
    border-radius: 5px;

    cursor: pointer;

    &::after {
        content: "";

        position: absolute;
        top: 32px;
        left: 0;

        width: 100%;
        height: 32px;

        background: #00E3A5;
        border-radius: 8px;
        
        opacity: 0.4;
        filter: blur(32px);
    }
}



/*
*   =========================
*   Primary / Outline
*   =========================
*/
.btn.btn-outline {
    color: $color-white;
    
    background-color: transparent;
    border: 1px solid $color-primary
}