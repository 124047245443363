/*
*   ==================================================
*   Header Stylesheet
*   <header />
*   ==================================================
*/



/*
*   =========================
*   Container
*   =========================
*/
header {
    padding: $spacing;
    padding-top: 2rem;
}



/*
*   =========================
*   Mobile Menu
*   =========================
*/
header .container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .burger-menu, .avatar {
        width: 2rem;
        height: 2rem;

        background-image: url(../../../../public/assets/menu.svg);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .logo {
        width: 8rem;
        height: 40px;

        background-image: url(../../../../public/assets/logo.svg);
        background-position: center 0.75rem;
        background-size: 7.25rem;
        background-repeat: no-repeat;

        a {
            display: flex;
            
            width: 100%;
            height: 100%;
        }
    }

    .avatar {
        position: relative;

        background-image: url(../../../../public/assets/avatar.png);

        cursor: pointer;

        &::after {
            content: "";

            position: absolute;
            top: -1px;
            right: -1px;

            width: 8px;
            height: 8px;

            background-color: $color-primary;
            border-radius: 50%;
        }
    }
}

header .dropdown-menu {
    padding: 0;
    margin-left: -$spacing;
    margin-right: -$spacing;

    background-color: #09251E;

    list-style-type: none;

    a {
        display: flex;

        padding: $spacing;
    }
}

header .main-nav {
    display: none;
}



/*
*   =========================
*   Desktop Menu
*   =========================
*/
@include for-desktop-up {
    header {
        padding: 40px;
        margin: 32px;

        background-color: rgba(#09251E, 0.3);
        border-radius: 10rem;
    
        .burger-menu {
            display: none !important;
        }
        
        .main-nav {
            display: flex;

            padding: 0;
            margin: 0;

            list-style-type: none;

            li a {
                position: relative;

                padding: calc($spacing / 2);

                color: #F5FBF2;
                font-size: 18px;
                font-weight: 500;
                line-height: 22px;
                letter-spacing: 0.02rem;

                &:hover::after {
                    content: "";
                    
                    position: absolute;

                    left: calc($spacing / 2);
                    bottom: 0;

                    width: 50px;
                    height: 4px;

                    background-color: $color-primary;
                    border-radius: 1rem;
                }
            }
        }
    }
}