/*
*   ==================================================
*   Section / Intro Section Stylesheet
*   #intro-section
*   ==================================================
*/



/*
*   =========================
*   Container
*   =========================
*/
#intro-section {
    padding-top: 2rem;
    
    .section-body {
        display: flex;
        flex-direction: column;
        align-items: center;
    
        h3 {
            margin: 0 auto;
            max-width: 250px;
    
            font-size: 1.5rem;
            font-weight: 400;
            line-height: 29px;
            text-align: center;
            letter-spacing: 0.01rem;
        }

        h1 {
            margin: 0;
            margin-top: 1rem;

            color: $color-white;
            font-size: 2.5rem;
            font-weight: 700;
            line-height: 150%;
            text-align: center;
            letter-spacing: 0.016rem;
        }

        .city-ping {
            margin-top: $spacing-lg;
            margin-left: -$spacing;

            width: calc(100% + 48px);
            height: 60vw;

            background-image: url(../../../../public/assets/city-ping.png);
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
        }

        .btn {
            margin-top: $spacing-x2;
        }
    }
}



/*
*   =========================
*   Desktop Menu
*   =========================
*/
@include for-desktop-up {
    #intro-section {
        .section-body {
            flex-direction: row;
            justify-content: space-between;

            .text {
                max-width: 550px;
            }

            h3 {
                margin: 0;

                font-size: 28px;
                font-weight: 400;
                line-height: 36px;
                text-align: left;
            }
    
            h1 {
                font-size: 40px;
                font-weight: 700;
                line-height: 60px;
                text-align: left;
            }
            
            .city-ping {
                margin: 0;
                
                width: 600px;
                height: 500px;
            }
        }
    }
}